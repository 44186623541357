import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getMyTrickProfit = createAsyncThunk(
  'MyTrickProfit/getMyTrickProfit',
  async (params, { dispatch }) => {
    const headers = {'Partner-Token':localStorage.getItem('Partner-Token')}
    try {
      const response = await axios.post(baseUrl +'/partnerapi/mytrickprofit', params,{ withCredentials:true, headers })
      return {
        myTrickProfit: response?.data?.data,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

// export const getHome = createAsyncThunk(
//     'MyTrickProfit/getHome',
//     async (params, { dispatch }) => {
//       const headers = {}
//       try {
//         const response = await axios.post(baseUrl +'/userapi/home', {},{ withCredentials:true, headers })
//         // //console.log('response', response)
//         return {
//             myHome: response?.data?.data,
//         }
//       } catch (e) {
//         toast.error(e?.response?.data?.message)
//       }
//     }
//   )

//   export const getHeaderDeatils = createAsyncThunk(
//     'MyTrickProfit/getHeaderDeatils',
//     async (params, { dispatch }) => {
//       const headers = {}
//       try {
//         const response = await axios.post(baseUrl +'/userapi/headerdata', {},{ withCredentials:true, headers })
//         // //console.log('response', response)
//         return {
//             headerDeatils: response?.data?.data,
//         }
//       } catch (e) {
//         toast.error(e?.response?.data?.message)
//       }
//     }
//   )

//   export const getMyTrick = createAsyncThunk(
//     'MyTrickProfit/getMyTrick',
//     async (params, { dispatch }) => {
//       const headers = {}
//       try {
//         const response = await axios.post(baseUrl +'/userapi/mytrick', {},{ withCredentials:true, headers })
//         return {
//           myTrick: response?.data?.data,
//         }
//       } catch (e) {
//         toast.error(e?.response?.data?.message)
//       }
//     }
//   )

export const home = createSlice({
  name: 'home',
  initialState: {
    myTrickProfit: {},
    loader: true,
    // params: {
    //   pageNo: 1,
    //   pageSize: 10,
    //   search: ''
    // },
    // totalPages: 0,
    // selected: null
  },
  reducers: {
    selectLevel: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMyTrickProfit.fulfilled, (state, action) => {
      state.myTrickProfit = action?.payload?.myTrickProfit
      state.loader = false
    })
   
  }
})

export const { selectLevel, setLoader, setPageNo, setPageSize, setSearch } =
  home.actions

export default home.reducer