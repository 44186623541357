import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getMyprofile = createAsyncThunk(
  'home/getMyprofile',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/myprofile', {},{ withCredentials:true, headers })
      return {
        myProfile: response?.data?.data,
      }
      // localStorage.setItem('userData', JSON.stringify(response.data.data))
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)

export const getTrickDetail = createAsyncThunk(
  'home/getTrickDetail',
  async (params, { dispatch }) => {
    const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
    try {
      const response = await axios.post(baseUrl +`/raapi/trickdetails`,{...params},{ withCredentials:true, headers })
      return {
        TrickDetail: response?.data?.data,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)

export const getHome = createAsyncThunk(
    'home/getHome',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/home', {},{ withCredentials:true, headers })
        return {
            myHome: response?.data?.data,
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
      }
    }
  )

export const getTopTen = createAsyncThunk(
    'home/getTopTen',
    async (params, { dispatch }) => {
      const headers = {}      
      try {
        const response = await axios.post(`${baseUrl}/userapi/gettopten/${params?.thirtyDay}/${params?.test}`, {},{ withCredentials:true, headers })
        return {
          topTen: response?.data?.data,
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
      }
    }
  )


  export const getHomeTrackList = createAsyncThunk(
    'home/getHomeTrackList',
    async (params, { dispatch }) => {
      //
      // debugger
      const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
      try {
        const response = await axios.post(baseUrl +'/raapi/hometrickers', params,{ withCredentials:true, headers })
        return {
          homeTrackList: response?.data?.data?.trick_list, 
            // noofPages: response?.data?.data?.noofPages,
            pageNumber: response?.data?.data?.pageNumber,
            pageSize: response?.data?.data?.pageSize,
          total: response?.data?.data?.total,
          Search: response?.data?.data?.Search,
          
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
        dispatch(setHomeTrackListLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
      }
    }
  )

  export const getHeaderDeatils = createAsyncThunk(
    'home/getHeaderDeatils',
    async (params, { dispatch }) => {
      const headers = {'Partner-Token':localStorage.getItem('Partner-Token')}
      try {
        const response = await axios.post(baseUrl +'/partnerapi/headerdata', {},{ withCredentials:true, headers })
        return {
          headerDetails: response?.data?.data,
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
      }
    }
  )

  export const getMyTrick = createAsyncThunk(
    'home/getMyTrick',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/mytrick', {},{ withCredentials:true, headers })
        return {
          myTrick: response?.data?.data,
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
      }
    }
  )

  export const linkAngelOne = createAsyncThunk(
    'home/linkAngelOne',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/linkangelone', params,{ withCredentials:true, headers })
        // toast.success(response?.data?.message)
        dispatch(getHeaderDeatils())
        return true
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
      }
    }
  )

  export const getAngelOne = createAsyncThunk(
    'home/linkAngelOne',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/getangelone', params,{ withCredentials:true, headers })
        // toast.success(response?.data?.message)
        // dispatch(getHeaderDeatils())
        return {
          angelOne:response?.data?.data?.broker_angelone_details,
          brokerDetails:response?.data?.data?.broker_details,
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return {
        angelOne:null,
      }
      }
    }
  )

  export const startTrick = createAsyncThunk(
    'home/startTrick',
    async (params, { dispatch }) => {
      
      const headers = {}
      try {
        await axios.post(baseUrl +'/userapi/starttrick', params,{ withCredentials:true, headers })
        // params?.isTestModeActive === 0 && await dispatch(SwitchToLive(TrackParam))
        dispatch(setTrackParam(params))
        return true
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      
      return false
      }
    }
  )


  export const setSetting = createAsyncThunk(
    'home/setSetting',
    async (params, { dispatch }) => {
      
      const headers = {'Partner-Token':localStorage.getItem('Partner-Token')}
      try {
       const response = await axios.post(baseUrl +'/partnerapi/createPartnerFromPartner', params,{ withCredentials:true, headers })
        // params?.isTestModeActive === 0 && await dispatch(SwitchToLive(TrackParam))
        toast.success('Partner successfully Added,Please share the link to partner to start on-boarding')
        dispatch(setLoader(false))
        dispatch(getHeaderDeatils())
        return response
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      
      return false
      }
    }
  )

  export const giveLicense = createAsyncThunk(
    'home/giveLicense',
    async (params, { dispatch }) => {
      
      const headers = {'Partner-Token':localStorage.getItem('Partner-Token')}
      try {
       const response = await axios.post(baseUrl +'/partnerapi/giveLicense', params,{ withCredentials:true, headers })
        // params?.isTestModeActive === 0 && await dispatch(SwitchToLive(TrackParam))
        toast.success('License successfully transfared')
        dispatch(setLoader(false))
        dispatch(getHeaderDeatils())
        return response
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      
      return false
      }
    }
  )

  export const SwitchToLive = createAsyncThunk(
    'home/SwitchToLive',
    async (params, { dispatch }) => {
      
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/testtolivetrick', params,{ withCredentials:true, headers })
        return true
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      
      return false
      }
    }
  )

  export const stopTrick = createAsyncThunk(
    'home/stopTrick',
    async (params, { dispatch }) => {
      
      const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
      try {
        const response = await axios.post(baseUrl +'/raapi/stoptrick', params,{ withCredentials:true, headers })
        // toast.success(response?.data?.message)
        dispatch(setTrackParam(params))
        return response?.data
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      
      return false
      }
    }
  )

  export const SellTrick = createAsyncThunk(
    'home/stopTrick',
    async (params, { dispatch }) => {
      const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
      try {
        const response = await axios.post(baseUrl +'/raapi/sellTrick', params,{ withCredentials:true, headers })
        // toast.success(response?.data?.message)
        dispatch(setTrackParam(params))
        return true
      } catch (e) {
        toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      
      return false
      }
    }
  )


  export const roboProPlans = createAsyncThunk(
    'home/roboProPlans',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/roboProPlans', params,{ withCredentials:true, headers })
        // toast.success(response?.data?.message)
        return response?.data?.data
      } catch (e) {
        toast.error(e?.response?.data?.message)
        dispatch(setLoader(false))
        dispatch(setError(e?.response?.data?.message))
      
        return false
      }
    }
  )

export const home = createSlice({
  name: 'home',
  initialState: {
    myProfile: {},
    angelOne:null,
    brokerDetails:null,
    dropdownOpen:false,
    myHome: [],
    topTen: [],
    proPlans: [],
    headerDetails:{},
    myTrick:[],
    TrickDetail:[],
    loader: false,
    homeTrackListLoader:true,
    homeTrackList:[],
    error:'',
    TrackParam:{},
      pageNumber: 1,
      pageSize: 12,
      Search: '',
    total: 0,
    selected: null
  },
  reducers: {
    setSelect: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setTrackParam: (state, action) => {
      state.TrackParam = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setHomeTrackListLoader: (state, action) => {
      state.homeTrackListLoader = action.payload
    },
    setDropDownOpen: (state, action) => {
      state.dropdownOpen = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload.pageNumber
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload.pageSize
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMyprofile.fulfilled, (state, action) => {
      state.myProfile = action?.payload?.myProfile
      state.loader = false
    })
    builder.addCase(getTrickDetail.fulfilled, (state, action) => {
      state.TrickDetail = action?.payload?.TrickDetail
      state.loader = false
    })

    builder.addCase(getHome.fulfilled, (state, action) => {
      state.myHome = action?.payload?.myHome
      state.loader = false
    })

    builder.addCase(getTopTen.fulfilled, (state, action) => {
      state.topTen = action?.payload?.topTen
      state.loader = false
    })

    builder.addCase(getHomeTrackList.fulfilled, (state, action) => {
      state.homeTrackList = action?.payload?.homeTrackList
      state.pageNumber = action?.payload?.pageNumber
      state.pageSize = action?.payload?.pageSize
      state.total = action?.payload?.total
      state.homeTrackListLoader = false
      state.loader = false
    })

    builder.addCase(getHeaderDeatils.fulfilled, (state, action) => {
      //console.log('action', action?.payload?.headerDetails)
      state.headerDetails = action?.payload?.headerDetails
      state.loader = false
    })
    builder.addCase(getMyTrick.fulfilled, (state, action) => {
    state.myTrick = action?.payload?.myTrick
    state.loader = false
    })
    builder.addCase(getAngelOne.fulfilled, (state, action) => {
      state.angelOne = action?.payload?.angelOne ?? null
      state.brokerDetails = action?.payload?.brokerDetails ?? null
      state.loader = false
    })

    builder.addCase(roboProPlans.fulfilled, (state, action) => {
      state.proPlans = action?.payload ?? []
      state.loader = false
    })

  }
})

export const { setSelect,setTrackParam, setLoader,setHomeTrackListLoader, setPageNumber,setDropDownOpen, setPageSize, setSearch } =
  home.actions

export default home.reducer