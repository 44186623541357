import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getRewardHistory = createAsyncThunk(
  'RewardHistory/getRewardHistory',
  async (params, { dispatch }) => {
    const headers = {'Partner-Token':localStorage.getItem('Partner-Token')}
    try {
      const response = await axios.post(baseUrl + '/partnerapi/myRewardhistory', params, { withCredentials: true, headers })
      return {
        RewardHistory: response?.data?.data?.wallet_transaction_list,
        pageNumber: response?.data?.data?.pageNumber,
        pageSize: response?.data?.data?.pageSize,
        total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)


export const home = createSlice({
  name: 'home',
  initialState: {
    RewardHistory: {},
    error: '',
    status: '',
    paramData: {},
    selected: null,
    pageNumber: 1,
    pageSize: 12,
    Search: '',
    total: 0,
    // AlertLoader: true,
    loader: true,
  },
  reducers: {
    selectLevel: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setParamData: (state, action) => {
      state.paramData = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRewardHistory.fulfilled, (state, action) => {
      state.RewardHistory = action?.payload?.RewardHistory
      state.pageNumber = action?.payload?.pageNumber
      state.pageSize = action?.payload?.pageSize
      state.total = action?.payload?.total
      state.loader = false
    })

  }
})

export const { selectLevel, setLoader, setPageNo, setPageSize, setSearch } =
  home.actions

export default home.reducer